@media (min-width: 768px) {
  .navbar-nav>li>a {
    /* (80px - line-height of 27px) / 2 = 26.5px */
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 27px;
  }
}

.icon-back {
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px;
}

.icon-coin {
  max-width: 18px;
  max-height: 18px;
  margin-right: 5px;
}

.icon-reload {
  max-width: 18px;
  max-height: 18px;
  margin-left: 5px;
}



.input-outer {
  outline: none !important;
  border-color: #286be9 !important;
  box-shadow: 0 0 20px #286be9 !important;
}