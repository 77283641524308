body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  height: 100%;
}

.bg {
  /* The image used */


  /* Full height */

  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.bg-match {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.start-box-amount {
  font-size: 14px;
}

.button-amount {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.button-amount button {
  border: none !important;
}


.amount-border-select button:focus {
  color: darkorange !important;
  background: none !important;
  background-color: transparent !important;
}

.amount-border-select button {
  color: darkorange !important;
}

.button-bar {
  max-width: 100%;
  height: 50px;
}