.choice-select {
  max-width: 50%;
  max-height: 50%;
}

.choice-selected {
  opacity: 1;
}

.choice-unselect {
  opacity: 0.3;
}

.choice-play {
  max-width: 90%;
  max-height: 90%;
}

.choice-win {
  opacity: 1;
}

.choice-lose {
  opacity: 0.3;
}



.result {
  width: 20%;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}